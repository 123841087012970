<template>
  <div>
    <div class="flex-box">
      <div>123</div>
      <div>223</div>
      <div>323</div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>
.flex-box {
  display: flex;
  flex-wrap: wrap;
}
.flex-box > div {
  /* flex: 1; */
  width: 50%;
}
</style>